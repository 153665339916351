import React, { useState } from "react";
import styled from "styled-components";
import { api } from "../../axiosConfig";
import ButtonLoader from "../includes/loader/ButtonLoader";

function AddDaysModal({
  isModal,
  setModal,
  token,
  dayId,
  setAddCount,
  addDayCount,
  title,
  dayClick,
}) {
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleAddClassFunction = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        `courses/add-days/${dayClick}/`,
        {
          days: addDayCount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const Data = response.app_data;
        if (response.app_data.StatusCode === 6000) {
          setModal(false);
          setLoading(false);
          setAddCount("");
        } else {
          setLoading(false);
          setErrorMsg(Data.data.message);
          setAddCount("");
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((error) => {});
  };
  const formData = new FormData();
  formData.append("number_of_content", addDayCount);
  const handleAddNoOfContents = (e) => {
    e.preventDefault();
    api
      .post(
        `courses/add-number-of-content-in-a-day/${dayId}/`,
        formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setModal(false);
          setAddCount("");
        } else {
          setAddCount("");
        }
      })
      .catch((error) => {});
  };
  return (
    <>
      <Container className={isModal && "active"}>
        <Overlay onClick={() => setModal(false)}></Overlay>
        <Modal>
          <Containers>
            <Cover
              onSubmit={(e) =>
                title !== "Number of contents"
                  ? handleAddClassFunction(e)
                  : handleAddNoOfContents(e)
              }
            >
              <Title>{title}</Title>
              <Input
                type="number"
                placeholder="0"
                value={addDayCount}
                onChange={(e) => {
                  setAddCount(e.target.value);
                }}
                required
              />
              {isLoading ? (
                <Submits>
                  <ButtonLoader />
                </Submits>
              ) : (
                <Submit type="submit" value="Submit" />
              )}
            </Cover>
            <Error> {errorMsg} </Error>
          </Containers>
        </Modal>
      </Container>
    </>
  );
}
export default AddDaysModal;

const Error = styled.div`
  text-align: center;
  color: #ff3e3e;
  margin-top: 30px;
`;
const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const MainContainer = styled.div``;
const Containers = styled.div`
  padding: 50px 0;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;
const Cover = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const Title = styled.div`
  color: #9d9d9d;
`;
const Input = styled.input`
  background-color: #5b5b5b;
  color: #fff;
  border: none;
  border-radius: 8px;
  outline: none;
  width: 80px;
  font-size: 18px;
  height: 40px;
  font-family: "mont-medium";
  padding: 0 20px;
  box-sizing: border-box;
`;
const Submit = styled.input`
  background-color: var(--lightblue);
  height: 40px;
  border: none;
  color: #000;
  cursor: pointer;
  font-family: "mont-medium";
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 18px;
  border-radius: 8px;
  box-sizing: border-box;
`;
const Submits = styled.div`
  background-color: var(--lightblue);
  height: 40px;
  color: #000;
  cursor: pointer;
  font-family: "mont-medium";
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 18px;
  border-radius: 8px;
  box-sizing: border-box;
`;
