import React from "react";
import { styled } from "styled-components";

function ConfirmationModal({ isModal, setModal, submitFunction }) {
  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Cover>
          <Title>Are You Sure You Want to Delete This?</Title>
          <CoverButton>
            <Button onClick={() => setModal(false)} type="no">
              No
            </Button>
            <Button
              onClick={() => {
                submitFunction();
              }}
              type="yes"
            >
              Yes
            </Button>
          </CoverButton>
        </Cover>
      </Modal>
    </Container>
  );
}

export default ConfirmationModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Cover = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const CoverButton = styled.div`
  display: flex;
  margin-top: 20px;
`;
const Button = styled.div`
  padding: 12px 20px;
  color: var(--textcolor);
  background-color: ${({ type }) => (type == "no" ? "#803232" : "#1b591b")};
  border: 1px solid var(--bordercolor);
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
const Title = styled.h2`
  color: var(--textcolor);
`;
