// imports
import program from "../../assets/images/sidebar/program.svg";
import days from "../../assets/images/sidebar/days.svg";
import student from "../../assets/images/sidebar/student.svg";
import topic from "../../assets/images/sidebar/topic.svg";
import career from "../../assets/images/sidebar/career.svg";
import enquiry_list from "../../assets/images/sidebar/enq-list.svg";
import team from "../../assets/images/sidebar/team.svg";
import testimonial from "../../assets/images/sidebar/testimonials.svg";
import achievements from "../../assets/images/sidebar/achievemnets.svg";
import enquiry from "../../assets/images/sidebar/enquiry.svg";
import whatsapp from "../../assets/images/sidebar/whatsapp.png";

import video_comp from "../../assets/images/topic/Video.svg";
import text_comp from "../../assets/images/topic/Text.svg";
import image_comp from "../../assets/images/topic/Image.svg";
import audio_comp from "../../assets/images/topic/AUdio.svg";
import en_video from "../../assets/videos/eng.mp4";
import image23 from "../../assets/images/team/team.png";

//blueicons
import blue_program from "../../assets/images/sidebar/blue/Programs.svg";
import blue_days from "../../assets/images/sidebar/blue/Days.svg";
import blue_student from "../../assets/images/sidebar/blue/Student.svg";
import blue_topic from "../../assets/images/sidebar/blue/Topic.svg";
import blue_career from "../../assets/images/sidebar/blue/Career.svg";
import blue_enquiry_list from "../../assets/images/sidebar/blue/CareerEnq.svg";
import blue_team from "../../assets/images/sidebar/blue/Team.svg";
import blue_testimonial from "../../assets/images/sidebar/blue/Testimonials.svg";
import blue_achievements from "../../assets/images/sidebar/blue/achievemnets.svg";
import blue_enquiry from "../../assets/images/sidebar/blue/Enquiry.svg";
import blue_whatsapp from "../../assets/images/sidebar/blue/whatsapp.png";

export const navList = [
  {
    id: 1,
    title: "Programs",
    image: program,
    blue_image: blue_program,
    route: "/programs",
  },
  {
    id: 21,
    title: "Days",
    image: days,
    blue_image: blue_days,
    route: "/days",
  },
  {
    id: 132,
    title: "Student",
    image: student,
    blue_image: blue_student,
    route: "/students",
  },
  {
    id: 3241,
    title: "Topic",
    image: topic,
    blue_image: blue_topic,
    route: "/topic",
  },
  {
    id: 2341,
    title: "Career",
    image: career,
    blue_image: blue_career,
    route: "/career",
  },
  {
    id: 2343241,
    title: "Gallery",
    image: testimonial,
    blue_image: blue_testimonial,
    route: "/gallery",
  },
  {
    id: 431,
    title: "Career Enquiries",
    image: enquiry_list,
    blue_image: blue_enquiry_list,
    route: "/enquiry-list",
  },
  {
    id: 15,
    title: "Our team",
    image: team,
    blue_image: blue_team,
    route: "/team",
  },
  {
    id: 1654,
    title: "Testimonials",
    image: testimonial,
    blue_image: blue_testimonial,
    route: "/testimonials",
  },
  {
    id: 321,
    title: "Achievements",
    image: achievements,
    blue_image: blue_achievements,
    route: "/achievements",
  },
  {
    id: 1222,
    title: "Enquiry",
    image: enquiry,
    blue_image: blue_enquiry,
    route: "/enquiry",
  },
  {
    id: 1223,
    title: "WhatsApp Number",
    image: whatsapp,
    blue_image: blue_whatsapp,
    route: "/whatsapp",
  },
];

export const total_counts = [
  {
    id: 1,
    title: "Entry Level",
    count: "5623",
  },
  {
    id: 12,
    title: "Secondary Level",
    count: "3215",
  },
  {
    id: 134,
    title: "Advanced Level",
    count: "2040",
  },
  {
    id: 1342,
    title: "Speak-up Sessions",
    count: "1245",
  },
  {
    id: 13432,
    title: "IELTS",
    count: "625",
  },
  {
    id: 15,
    title: "Crack Your Interview",
    count: "856",
  },
  {
    id: 1654,
    title: "EC Junior Course",
    count: "954",
  },
];

export const tabs = [
  {
    id: 12134,
    title: "Entry Level",
  },
  {
    id: 1214,
    title: "Secondary Level",
  },
  {
    id: 1134,
    title: "Advanced Level",
  },
  {
    id: 134,
    title: "Speak-up section",
  },
  {
    id: 128134,
    title: "IELTS",
  },
  {
    id: 129134,
    title: "Crack your interview",
  },
  {
    id: 11734,
    title: "EC junior",
  },
];

export const students = [
  {
    id: 25,
    name: "Student 1",
    username: "ec_student_01",
    password: "#52EC9@2",
    phone: "+91 7306608824",
    program: "Entry Level",
  },
  {
    id: 26,
    name: "Student 2",
    username: "ec_student_02",
    password: "#5HG59@2",
    phone: "+91 9406608824",
    program: "Entry Level",
  },
  {
    id: 25,
    name: "Student 3",
    username: "ec_student_03",
    password: "#89EC9@2",
    phone: "+91 8556608824",
    program: "Entry Level",
  },
  {
    id: 25,
    name: "Student 4",
    username: "ec_student_04",
    password: "#63EC9@2",
    phone: "+91 940008824",
    program: "Advanced Level",
  },
  {
    id: 25,
    name: "Student 5",
    username: "ec_student_05",
    password: "#75C9&63",
    phone: "+91 7856594624",
    program: "Entry Level",
  },
  {
    id: 25,
    name: "Student 6",
    username: "ec_student_06",
    password: "#EC568&21",
    phone: "+91 6548566824",
    program: "Entry Level",
  },
  {
    id: 25,
    name: "Student 7",
    username: "ec_student_07",
    password: "#64656@U@2",
    phone: "+91 730665644",
    program: "Entry Level",
  },
];

export const ordered_content = [
  {
    id: "#231",
    order_id: "",
    type: "text",
    image: text_comp,
    is_visible: false,
  },
  {
    id: "#2131",
    order_id: "",
    type: "audio",
    image: audio_comp,
    is_visible: false,
  },
  {
    id: "#2331",
    order_id: "",
    type: "video",
    image: video_comp,
    is_visible: false,
  },
  {
    id: "#2531",
    order_id: "",
    type: "image",
    image: image_comp,
    is_visible: false,
  },
];

export const preview_content = [
  {
    id: "WE122",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting
       industry. Lorem Ipsum has been the industry's standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type
         and scrambled it to make a type specimen book. It has survived not
          only five centuries, `,
    order_id: 2,
    type: "text",
  },
  {
    id: "WE152",
    content: en_video,
    order_id: 2,
    type: "video",
  },
  {
    id: "WE162",
    content:
      "https://basicenglishspeaking.com/wp-content/uploads/audio/QA/QA-01.mp3?_=1",
    order_id: 2,
    type: "audio",
  },
  {
    id: "WE922",
    content:
      "https://imgv2-1-f.scribdassets.com/img/document/462012684/original/8f86723d36/1686287861?v=1",
    order_id: 2,
    type: "image",
  },
];

export const careers_list = [
  {
    id: "ec_548",
    designation: "Councellor",
    job_type: "Full time",
    description:
      "Nulla facilisi. Integer volutpat varius ipsum, sit amet fringilla magna facilisis a. Proin finibus tempor mi, id tincidunt libero pharetra in. Praesent tempor arcu diam, a pharetra odio faucibus sed. Nam tincidunt volutpat arcu. Donec nulla mi, dictum at sagittis sit amet, sodales a tellus.",
  },
  {
    id: "ec_548",
    designation: "Language trainer",
    job_type: "Part time",
    description:
      "Nulla facilisi. Integer volutpat varius ipsum, sit amet fringilla magna facilisis a. Proin finibus tempor mi, id tincidunt libero pharetra in. Praesent tempor arcu diam, a pharetra odio faucibus sed. Nam tincidunt volutpat arcu. Donec nulla mi, dictum at sagittis sit amet, sodales a tellus.",
  },
  {
    id: "ec_548",
    designation: "Receptionist",
    job_type: "Full time",
    description:
      "Nulla facilisi. Integer volutpat varius ipsum, sit amet fringilla magna facilisis a. Proin finibus tempor mi, id tincidunt libero pharetra in. Praesent tempor arcu diam, a pharetra odio faucibus sed. Nam tincidunt volutpat arcu. Donec nulla mi, dictum at sagittis sit amet, sodales a tellus.",
  },
];

export const career_enquiries = [
  {
    id: "ec_85w6",
    name: "Shafeeq",
    email: "shafeeq@example.com",
    phone: "98XXXXXXX7",
    cv: "",
  },
  {
    id: "ec_8e856",
    name: "Vinod",
    email: "vinod@example.com",
    phone: "98XXXXXXX7",
    cv: "",
  },
  {
    id: "ec_854565w6",
    name: "Akhil",
    email: "akhil@example.com",
    phone: "98XXXXXXX7",
    cv: "",
  },
  {
    id: "ec_85962548w6",
    name: "Chandra",
    email: "chandra@example.com",
    phone: "98XXXXXXX7",
    cv: "",
  },
  {
    id: "ec_85w786586856",
    name: "Mustaq",
    email: "mustaq@example.com",
    phone: "98XXXXXXX7",
    cv: "",
  },
  {
    id: "ec_85w6",
    name: "Anjali",
    email: "anjali@example.com",
    phone: "98XXXXXXX7",
    cv: "",
  },
];

export const team_list = [
  {
    id: "ec_5678",
    name: "Shibil Kazim",
    designation: "Language Trainer",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shibil Kazim",
    designation: "Language Trainer",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shibil Kazim",
    designation: "Language Trainer",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shibil Kazim",
    designation: "Language Trainer",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shibil Kazim",
    designation: "Language Trainer",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shibil Kazim",
    designation: "Language Trainer",
    image: image23,
  },
];

export const testimonial_list = [
  {
    id: "ec_5678",
    name: "Shyam",
    rating_count: 3,
    video: en_video,
    text: "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shyam",
    rating_count: 3,
    text: "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
    video: "",
    image: image23,
  },
  {
    id: "ec_5678",
    name: "Shyam",
    rating_count: 3,
    text: "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
    video: en_video,
    image: image23,
  },
];

export const achievements_list = [
  {
    id: "ec_5678",
    title: "Shyam",
    description:
      "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
    image: image23,
  },
  {
    id: "ec_5678",
    title: "Shyam",
    description:
      "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
    image: image23,
  },
  {
    id: "ec_5678",
    title: "Shyam",
    description:
      "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
    image: image23,
  },
];

export const enquiries = [
  {
    id: "ec_5678",
    name: "Shyam",
    email: "shyamk.accolades@gmail.com",
    phone: 8921924445,
    message:
      "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
  },
  {
    id: "ec_5678",
    name: "Shyam",
    email: "shyamk.accolades@gmail.com",
    phone: 8921924445,
    message:
      "We pave way for your learning process to remain enjoyable and easy-going. We are surely a one-stop solution for your English language related needs, as we dispel the reputation of English being so terrifyingly difficult. From English Cafe, you will procure the art of learning English in one of the simplest of ways.",
  },
];
