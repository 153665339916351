import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";
import { Context } from "../../context/Store";
import api from "../../general/api";
import AddStudent from "../../modal/AddStudent";
import Nodata from "../../includes/noData/Nodata";
import ConfirmationModal from "../../modal/ConfirmationModal";
import ReactPaginate from "react-paginate";

function Students() {
  const [isModal, setModal] = useState(false);
  const [getStudents, setStudents] = useState([]);
  const [modalType, setModalType] = useState();
  const [studentId, setStudentId] = useState();
  const [searchItems, setSearchItems] = useState("");
  const [isDelete, setDelete] = useState(false);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Student",
      },
    });
  }, []);

  useEffect(() => {
    api
      .get(`accounts/get-students/?q=${searchItems}`, {
        params: {
          page: currentPage,
        },
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const data = res.app_data.data;
        if (res.app_data.StatusCode == 6000) {
          setStudents(data);
          setPagination(res.app_data.pagination_data);
        } else {
          setStudents([]);
        }
      });
  }, [isModal, searchItems, isDelete, currentPage]);

  const handleDeleteStudent = () => {
    api
      .post(
        `accounts/delete-student/${studentId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setDelete(false);
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Students</Title>
            <CoverTop>
              <SearchBar>
                <Input
                  type="search"
                  placeholder="Search..."
                  value={searchItems}
                  onChange={(e) => setSearchItems(e.target.value)}
                />
                <SearchIcon>
                  <i class="ri-search-line"></i>
                </SearchIcon>
              </SearchBar>
              <Button
                onClick={() => {
                  setModal(true);
                  setModalType("add");
                }}
              >
                Add Student
              </Button>
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">Sl No</CardTit>
            <CardTit type="name">Name</CardTit>
            <CardTit type="user">User Name</CardTit>
            <CardTit type="user">Password</CardTit>
            <CardTit type="phone">Phone</CardTit>
            <CardTit type="phone">Program</CardTit>
            <CardTit type="ems"></CardTit>
            <CardTit type="ems"></CardTit>
          </CardTitles>
          <Cards>
            {getStudents?.length == 0 ? (
              <Nodata />
            ) : (
              getStudents?.map((item, index) => (
                <Card key={index}>
                  <Name type="sl">{index + 1} </Name>
                  <Name type="name">{item.name} </Name>
                  <Name type="user">{item.username} </Name>
                  <Name type="user">{item.password} </Name>
                  <Name type="phone">{item.phone} </Name>
                  <Name type="phone">{item.programmes} </Name>
                  <Name
                    type="ems"
                    onClick={() => {
                      setModalType("edit");
                      setModal(true);
                      setStudentId(item.id);
                    }}
                  >
                    <i class="ri-edit-2-line"></i>
                  </Name>
                  <Name
                    type="ems"
                    onClick={() => {
                      setDelete(true);
                      setStudentId(item.id);
                    }}
                  >
                    <i class="ri-delete-bin-6-line"></i>
                  </Name>
                </Card>
              ))
            )}
          </Cards>
          {pagination &&
            pagination.total_pages > 1 &&
            getStudents.length > 0 && (
              <PaginationContainer>
                <PaginationText>
                  Showing students : {pagination.first_item} -{" "}
                  {pagination.last_item} of {pagination.total_items} Students
                </PaginationText>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pagination.total_pages}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />
              </PaginationContainer>
            )}
        </ProgramSection>
        <AddStudent
          isModal={isModal}
          setModal={setModal}
          type={modalType}
          id={studentId}
        />
        <ConfirmationModal
          isModal={isDelete}
          setModal={setDelete}
          submitFunction={handleDeleteStudent}
        />
      </Wrapper>
    </Container>
  );
}

export default Students;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "200px"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 20px;
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "200px"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  padding: ${({ type }) =>
    type == "ems" ? "0" : type == "sl" ? "0" : "0 0 0 10px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "flex-start"};
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  height: 50px;
  display: flex;
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
`;
// const CardTitles = styled.div``;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
