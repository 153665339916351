import "./assets/css/style.css";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./components/routing/router/MainRouter";
import "remixicon/fonts/remixicon.css";
import "react-quill/dist/quill.snow.css";
import Store from "./components/context/Store";
import "./assets/css/alert.scss";
import "./assets/js/index.js";

function App() {
  return (
    <Store>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </Store>
  );
}

export default App;
