import axios from "axios";

//LIVE SERVER
const api = axios.create({
  baseURL: "https://englishcafeacademy.in/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

//DEMO SERVER
// const api = axios.create({
//   baseURL: "https://englishcafe-demo.accoladesweb.com/api/v1/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

let isRefreshing = false;
let refreshPromiseQueue = [];

// Helper function to get token from local storage
const getTokenFromLocalStorage = () => {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  return userData?.access_token;
};

// Helper function to update token in local storage
const updateTokenInLocalStorage = (newToken) => {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  userData.access_token = newToken;
  localStorage.setItem("user_data", JSON.stringify(userData));
};

// Helper function to refresh access token
const refreshAccessToken = async () => {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const refreshToken = userData?.refresh_token;

  try {
    const refreshedTokenResponse = await axios.post(
      `https://englishcafeacademy.in/api/v1/accounts/token/refresh/`,
      {
        refresh: refreshToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // Update the default headers with the new token
    const newToken = refreshedTokenResponse?.data?.access;
    api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

    // Update token in local storage
    updateTokenInLocalStorage(newToken);

    return refreshedTokenResponse;
  } catch (error) {
    throw error;
  }
};

// ...
const userData = JSON.parse(localStorage.getItem("user_data"));

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (
      error.response.request.response ==
      '{"detail":"User not found","code":"user_not_found"}'
    ) {
      window.localStorage.clear();
      window.location.reload();
    }
    const originalRequest = error.config;

    if (error.response && error.response.status === 403) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshedTokenResponse = await refreshAccessToken();
          const newToken = refreshedTokenResponse?.data?.access;

          // Retry the original request with the new token
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          // logoutUser(); // Example: Logout the user
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          window.location.reload();
        }
      } else {
        // Queue the request for later retry after token refresh
        return new Promise((resolve) => {
          refreshPromiseQueue.push(resolve);
        }).then((newToken) => {
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return api(originalRequest);
        });
      }
    } else if (error.response && error.response.status === 400) {
    } else if (error.request) {
    } else {
    }
    return Promise.reject(error);
  }
);

export default api;
