import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { tabs } from "../../general/array";
import AddDaysModal from "../../modal/AddDaysModal";
import HeadTopBar from "../../includes/HeadTopBar";
import { Context } from "../../context/Store";
import api from "../../general/api";
import Nodata from "../../includes/noData/Nodata";
import ConfirmationModal from "../../modal/ConfirmationModal";

function Days() {
  const [dayClick, setDayClick] = useState();
  const [isModal, setModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [content, setContent] = useState("");
  const [addDayCount, setAddCount] = useState();
  const [getProgrames, setProgrames] = useState();
  const [isClicked, setClicked] = useState();
  const [selectedId, setSelectedId] = useState();
  const [daysList, setDaysList] = useState([]);
  const [dayId, setDayId] = useState("");

  const handleModal = () => {
    setModal(!isModal);
  };
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Days",
      },
    });
  }, []);
  const access_token = user_data.access_token;
  useEffect(() => {
    api
      .get("courses/programmes/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const StatusCode = response.app_data.StatusCode;
        const Data = response.app_data.data;
        if (StatusCode === 6000) {
          setProgrames(Data);
          setSelectedId(Data[0].id);
          setClicked(getProgrames[0].id);
        } else {
        }
      })
      .catch((error) => {});
  }, []);
  // -------------days list------------------
  useEffect(() => {
    api
      .get(`courses/days-list/?programme_id=${selectedId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const StatusCode = response.app_data.StatusCode;
        const Data = response.app_data.data;
        if (StatusCode === 6000) {
          setDaysList(Data);
        } else {
          setDaysList([]);
        }
      })
      .catch((error) => {});
  }, [isClicked, isModal, selectedId, isDelete, daysList]);
  const handleDelete = () => {
    api
      .post(
        `courses/delete-days/${selectedId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token} `,
          },
        }
      )
      .then((response) => {
        const { StatusCode, data } = response.app_data;
        if (StatusCode === 6000) {
          setDelete(false);
        } else {
        }
      })
      .catch((error) => {
        // setError(true)
      });
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <DaysSection>
          <CoverDays>
            <TitleDay>Days</TitleDay>
            <Info>
              <CoverRound>
                <Round className="green"></Round>
                <TextInfo>Number of Contents Already Added</TextInfo>
              </CoverRound>
              <CoverRound>
                <Round></Round>
                <TextInfo>Add Number of Contents</TextInfo>
              </CoverRound>
            </Info>
          </CoverDays>
          <DaysCollection>
            <Tabs>
              {getProgrames?.map((item, index) => (
                <Tab
                  className={selectedId == item.id && "active"}
                  onClick={() => setSelectedId(item.id)}
                  key={index}
                >
                  {item.name}
                </Tab>
              ))}
            </Tabs>
            <DaysColoumn>
              <TopAdd>
                <Add
                  onClick={() => {
                    setModal(true);
                    setContent("Number of days");
                  }}
                >
                  Add Days
                </Add>
                {daysList?.length > 0 && (
                  <DeleteAll onClick={() => setDelete(true)}>
                    Delete all
                  </DeleteAll>
                )}
              </TopAdd>
              <Cards type={daysList?.length}>
                {daysList?.length == 0 ? (
                  <Nodata />
                ) : (
                  daysList?.map((item, index) => (
                    <Card
                      className={dayClick == item.id && "active"}
                      onClick={() => {
                        setDayClick(item.id);
                        setContent("Number of contents");
                        setModal(true);
                      }}
                      key={index}
                      type={item.no_of_contents}
                    >
                      Day {item.day_number}
                    </Card>
                  ))
                )}
              </Cards>
            </DaysColoumn>
          </DaysCollection>
        </DaysSection>
        <AddDaysModal
          setModal={setModal}
          isModal={isModal}
          addDayCount={addDayCount}
          title={content}
          setAddCount={setAddCount}
          handleAssignModal={handleModal}
          token={access_token}
          dayClick={selectedId}
          dayId={dayClick}
        />
        <ConfirmationModal
          isModal={isDelete}
          setModal={setDelete}
          submitFunction={handleDelete}
        />
      </Wrapper>
    </Container>
  );
}

export default Days;

const Container = styled.div`
  padding: 80px 0;
  overflow-y: hidden;
`;

const Wrapper = styled.div``;
const DeleteAll = styled.div`
  cursor: pointer;
  border: 1px solid var(--bordercolor);
  padding: 10px;
  color: #a35252;
  border-radius: 10px;
`;
const TopAdd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Cards = styled.div`
  display: flex;
  justify-content: ${({ type }) => (type == 0 ? "center" : "flex-start")};
  flex-wrap: wrap;
  gap: 10px;
`;

const DaysSection = styled.div`
  margin-top: 40px;
`;
const TitleDay = styled.h3`
  margin-bottom: 25px;
`;
const DaysCollection = styled.div``;
const Tabs = styled.div`
  display: flex;
`;
const Tab = styled.div`
  background-color: #0c0d0f;
  font-size: 14px;
  padding: 16px 30px;
  width: 100px; /* Set the width of the container */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--bordercolor);
  color: #ffffff8e;
  cursor: pointer;
  margin-right: 3px;
  transition: 0.4s ease;
  &.active {
    transition: 0.4s ease;
    border-radius: 0px 20px 0 0;
    background-color: var(--darkgrey);
    border-left: 1px solid var(--bordercolor);
    border-right: 1px solid var(--bordercolor);
    border-top: 1px solid var(--bordercolor);
    border-bottom: 1px solid transparent;
  }
`;
const DaysColoumn = styled.div`
  background-color: var(--darkgrey);
  padding: 30px 60px;
  border-left: 1px solid var(--bordercolor);
  border-right: 1px solid var(--bordercolor);
  border-bottom: 1px solid var(--bordercolor);
`;
const Add = styled.div`
  background-color: var(--lightblue);
  width: fit-content;
  padding: 10px 16px;
  color: #000;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  cursor: pointer;
`;
const Card = styled.div`
  background-color: var(--lightgrey);
  border: ${({ type }) =>
    type > 0 ? "1px solid #4e7b4e" : "1px solid #7c4747"};
  width: 85px;
  cursor: pointer;
  font-size: 14px;
  height: 60px;
  color: #ffffff8e;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  &.active {
    color: var(--lightblue);
    /* border-color: 1px solid var(--lightblue); */
  }
`;
const Info = styled.div``;
const CoverDays = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CoverRound = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const Round = styled.div`
  background-color: #7c4747;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;

  &.green {
    background-color: #4e7b4e;
  }
`;
const TextInfo = styled.h5`
  color: #757474;
`;
