import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AppRouter from "./AppRouter";
import AuthRoute from "../routes/AuthRoute";
import AuthRouter from "./AuthRouter";
import PrivateRoute from "../routes/PrivateRoute";
import { Context } from "../../context/Store";
import SectionLoader from "../../includes/loader/SectionLoader";
import { styled } from "styled-components";
import NotinMobile from "../../includes/not/NotinMobile";

function MainRouter() {
  const { dispatch } = useContext(Context);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    console.log(user_data, "from main");
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
    setTimeout(() => setLoading(false), 1100);
  }, []);
  return isLoading ? (
    <SectionLoader />
  ) : (
    <>
      <Container>
        <Routes>
          <Route
            path="auth/*"
            element={
              <AuthRoute>
                <AuthRouter />
              </AuthRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <AppRouter />
              </PrivateRoute>
            }
          />
        </Routes>
      </Container>
      <Mob>
        <NotinMobile />
      </Mob>
    </>
  );
}

export default MainRouter;
const Container = styled.div`
  @media all and (max-width: 980px) {
    display: none;
  }
`;
const Mob = styled.div`
  display: none;
  @media all and (max-width: 980px) {
    display: block;
  }
`;
