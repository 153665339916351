import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../context/Store";
import { api } from "../../axiosConfig";
import ButtonLoader from "../includes/loader/ButtonLoader";

function AddStudent({ isModal, setModal, type, id }) {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [programme, setProgramme] = useState();
  const [getPrgramme, setGetPrograme] = useState();

  const [isLoading, setLoading] = useState(false);

  const {
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  const handleModal = () => {
    setName("");
    setPhone("");
    setPassword("");
    setProgramme("");
    setModal(false);
  };
  useEffect(() => {
    if (type == "add") {
      setName("");
      setPhone("");
      setPassword("");
      setProgramme("");
    }
  }, [type]);
  // -----------------Add program--------------------
  const assignHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        "accounts/create-student-profile/",
        {
          name: name,
          phone: phone,
          password: password,
          programme: programme,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.app_data.StatusCode === 6000) {
          setLoading(false);
          setName("");
          setPhone("");
          setPassword("");
          setProgramme("");
          setModal(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  const editHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        `accounts/edit-students/${id}/`,
        {
          name: name,
          phone: phone,
          programme_id: programme,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setLoading(false);
          setModal(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  //   -----------get programmes-------------------
  useEffect(() => {
    api
      .get("courses/programmes/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.data.app_data;
        if (StatusCode === 6000) {
          setGetPrograme(response.data.app_data.data);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
        // setError(true)
      });
  }, [isModal]);
  useEffect(() => {
    type == "edit" &&
      api
        .get(`accounts/student/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const { StatusCode } = response.data.app_data;
          const data = response.data.app_data.data;
          if (StatusCode === 6000) {
            setName(data.name);
            setPhone(data.phone);
            setPassword(data.password);
            setProgramme(data.programme_id);
          } else {
          }
        })
        .catch((error) => {
          // console.log(error);
          // setError(true)
        });
  }, [isModal]);

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => handleModal()}></Overlay>
      <Modal>
        <Containers
          onSubmit={(e) => (type == "edit" ? editHandler(e) : assignHandler(e))}
        >
          <Cover>
            <Label>Student Name</Label>
            <Input
              type="text"
              placeholder="Enter Student Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Cover>
          <Cover>
            <Label>Phone</Label>
            <CoverInput>
              <Input
                // className="num"
                type="number"
                placeholder="Enter Phone Number"
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Password</Label>
            <CoverInput>
              <Input
                // className="num"
                type="text"
                placeholder="Enter Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Programme</Label>
            <Select onChange={(e) => setProgramme(e.target.value)} required>
              <option> Select a programme</option>
              {getPrgramme?.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Select>
          </Cover>
          <Covers>
            {isLoading ? (
              <Submit>
                <ButtonLoader />
              </Submit>
            ) : (
              <Submits type="submit" value="Submit" />
            )}
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddStudent;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 60%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.div`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Submits = styled.input`
  background-color: var(--lightblue);
  color: #000;
  border: none;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;
