import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import EditPhone from "../../modal/EditPhone";
import { accountsConfig } from "../../../axiosConfig";
import { Context } from "../../context/Store";
import Nodata from "../../includes/noData/Nodata";
import api from "../../general/api";

const Whatsapp = () => {
  const [isModal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState("");
  const [phone, setPhone] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  const handleEditButton = (item) => {
    setModal(true);
    setItemData(item);
    setPhone(item.phone);
  };

  const fetchWhatsAppApi = () => {
    api
      .get("company_profile/get-whatsapp-number/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;

        if (StatusCode === 6000) {
          setData(Data);
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    fetchWhatsAppApi();
  }, []);

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "WhatsApp Number",
      },
    });
  }, []);

  console.log(itemData, "-=-=-=-=-Item-=-=-=-");
  return (
    <div>
      <Container>
        <Wrapper className="wrapper">
          <HeadTopBar />
          <ProgramSection>
            <TopSection>
              <Title>Team</Title>
              <CoverTop>
                <SearchBar>
                  <Input
                    type="search"
                    placeholder="Search..."
                    // value={searcheditems}
                  />
                  <SearchIcon>
                    <i class="ri-search-line"></i>
                  </SearchIcon>
                </SearchBar>
                {/* <Button>Add Member</Button> */}
              </CoverTop>
            </TopSection>
            <CardTitles>
              <CardTit type="sl">Sl No</CardTit>
              <CardTit type="name">Number</CardTit>

              <CardTit type="ems"></CardTit>
              <CardTit type="em"></CardTit>
            </CardTitles>
            <Cards>
              {data?.length == 0 ? (
                <Nodata />
              ) : (
                data?.map((item, index) => (
                  <Card>
                    <Name type="sl">{index + 1}</Name>
                    <Name type="name">{item.phone} </Name>
                    <Name type="ems" onClick={() => handleEditButton(item)}>
                      <i class="ri-edit-2-line"></i>
                    </Name>
                    {/* <Name type="em">Delete</Name> */}
                  </Card>
                ))
              )}
            </Cards>
          </ProgramSection>
          <EditPhone
            isModal={isModal}
            setModal={setModal}
            itemData={itemData}
            setItemData={setItemData}
            setPhone={setPhone}
            phone={phone}
            fetchWhatsAppApi={fetchWhatsAppApi}
          />
        </Wrapper>
      </Container>
    </div>
  );
};

export default Whatsapp;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const ImageCont = styled.div`
  width: 100px;
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "6%"
      : type == "name"
      ? "32%"
      : type == "user"
      ? "35%"
      : type == "phone"
      ? "25%"
      : type == "em"
      ? "8%"
      : type == "image"
      ? "10%"
      : type == "ems"
      ? "4%"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ type }) =>
    type == "ems"
      ? "0"
      : type == "sl"
      ? "0 0 0 15px"
      : type === "cv"
      ? "0 15px 0 15px"
      : type === "em"
      ? "0 15px 0 15px"
      : "0 0 0 15px"};
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "6%"
      : type == "name"
      ? "32%"
      : type == "user"
      ? "35%"
      : type == "phone"
      ? "25%"
      : type == "em"
      ? ""
      : type == "image"
      ? "10%"
      : type == "ems"
      ? "4%"
      : ""};
  padding: ${({ type }) =>
    type == "ems"
      ? "0"
      : type == "sl"
      ? "0"
      : type === "image"
      ? "0 15px 0 15px"
      : type === "em"
      ? "0 15px 0 15px"
      : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "flex-start"};
  cursor: ${({ type }) =>
    type == "ems"
      ? "pointer"
      : type == "em"
      ? "pointer"
      : type == "cv"
      ? "pointer"
      : ""};
  height: ${({ type }) => (type == "image" ? "150px" : "50px")};
  color: ${({ type }) =>
    type == "em" ? "#a35252" : type === "cv" ? "#6a6aff" : "#ffffff8e"};

  display: flex;
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  a {
    display: flex;
    text-decoration: none;
    color: #6a6aff;
  }
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
// const CardTitles = styled.div``;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
