import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { api } from "../../axiosConfig";
import { Context } from "../context/Store";
import ButtonLoader from "../includes/loader/ButtonLoader";

const CommonModal = ({ isModal, setModal, type }) => {
  const [name, setName] = useState("");
  const [quote, setQuote] = useState("");
  const [image, setImage] = useState();
  const [ratingCount, setRatingCount] = useState("");
  const [video, setVideo] = useState();

  const [achievementImage, setAchievementImage] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isLoading, setLoading] = useState(false);

  const {
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  const formData = new FormData();
  formData.append("name", name);
  formData.append("quote", quote);
  if (image) {
    formData.append("image", image);
  }
  formData.append("rating_count", ratingCount);

  if (video) {
    formData.append("video", video);
  }
  //  ---------------------Add testimonial---------------------
  const handleAddTestimonial = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        `company_profile/add-testimonials/`,
        formData,

        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setRatingCount("");
          setName("");
          setQuote("");
          setModal(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };

  //  ---------------------Add Achievements---------------------
  const achieveformData = new FormData();
  achieveformData.append("title", title);
  // achieveformData.append("description", description);
  if (achievementImage) {
    achieveformData.append("image", achievementImage);
  }
  const handleAchievements = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        `company_profile/add-achievement/`,
        achieveformData,

        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.app_data.StatusCode === 6000) {
          setTitle("");
          setAchievementImage(null);
          setModal(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <ContentContainer
          onSubmit={(e) =>
            type == "add_achievement"
              ? handleAchievements(e)
              : type == "add_testimonial"
              ? handleAddTestimonial(e)
              : ""
          }
        >
          {type == "add_testimonial" ? (
            <>
              <Cover>
                <Label>Name</Label>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </Cover>
              <Cover>
                <Label>Rating Count</Label>
                <CoverInput>
                  <Input
                    className="num"
                    required
                    type="number"
                    placeholder="0"
                    value={ratingCount}
                    onChange={(e) => setRatingCount(e.target.value)}
                  />
                </CoverInput>
              </Cover>
              <Cover>
                <Label>Text</Label>
                <TextArea
                  required
                  value={quote}
                  onChange={(e) => setQuote(e.target.value)}
                ></TextArea>
              </Cover>
              <Cover>
                <Label>Image</Label>
                <Input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Cover>
              <Cover>
                <Label>Video</Label>
                <Input
                  type="file"
                  onChange={(e) => setVideo(e.target.files[0])}
                />
              </Cover>
              <Cover>
                {isLoading ? (
                  <Submits>
                    <ButtonLoader />
                  </Submits>
                ) : (
                  <Submit type="submit" value="Submit" />
                )}
              </Cover>
            </>
          ) : type == "add_achievement" ? (
            <>
              <Cover>
                <Label>Title</Label>
                {/* <Input
                  type="text"
                  placeholder="Enter Program Name"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                /> */}
                <TextArea
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                ></TextArea>
              </Cover>
              {/* <Cover>
                <Label>Description</Label>
                <TextArea
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></TextArea>
              </Cover> */}
              <Cover>
                <Label>Image</Label>
                <Input
                  type="file"
                  onChange={(e) => setAchievementImage(e.target.files[0])}
                />
              </Cover>
              <Cover>
                {isLoading ? (
                  <Submits>
                    <ButtonLoader />
                  </Submits>
                ) : (
                  <Submit type="submit" value="Submit" />
                )}
              </Cover>
            </>
          ) : (
            ""
          )}
        </ContentContainer>
      </Modal>
    </Container>
  );
};

export default CommonModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const ContentContainer = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Cover = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 60%;
  height: 40px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  &.num {
    width: 20%;
    margin-right: 20px;
  }
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 60%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submits = styled.div`
  background-color: var(--lightblue);
  color: #000;

  width: 100%;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
`;
const Submit = styled.input`
  border: none;
  background-color: var(--lightblue);
  color: #000;
  width: 100%;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;
const CoverInput = styled.div`
  width: 60%;
`;
