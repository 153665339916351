import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../screens/login/Login";
import Dashboard from "../../screens/dashboard/Dashboard";
import Sidebar from "../../includes/sidebar/Sidebar";
import { styled } from "styled-components";
import Days from "../../screens/days/Days";
import Programs from "../../screens/program/Programs";
import Students from "../../screens/students/Students";
import Topics from "../../screens/topics/Topics";
import Career from "../../screens/career/Career";
import CareerEnquiries from "../../screens/career-enquiry-list/CareerEnquiries";
import Team from "../../screens/team/Team";
import Testimonials from "../../screens/testimonials/Testimonials";
import Achievements from "../../screens/achievements/Achievements";
import Enquiry from "../../screens/enquiry/Enquiry";
import GrowthOverview from "../../screens/growth/GrowthOverview";
import Gallery from "../../screens/gallery/Gallery";
import Whatsapp from "../../screens/whatsapp/Whatsapp";

function AppRouter() {
  return (
    <Container>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/days" element={<Days />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/students" element={<Students />} />
          <Route path="/topic" element={<Topics />} />
          <Route path="/career" element={<Career />} />
          <Route path="/team" element={<Team />} />
          <Route path="/growth-overview" element={<GrowthOverview />} />
          <Route path="/enquiry-list" element={<CareerEnquiries />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/achievements" element={<Achievements />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/whatsapp" element={<Whatsapp />} />
        </Routes>
      </Right>
    </Container>
  );
}

export default AppRouter;
const Container = styled.div`
  height: 100vh;
  background: #232327;
  color: #fff;
  display: flex;
`;
const Left = styled.div`
  width: 16%;
  height: 100%;
  @media all and (max-width: 1440px) {
    width: 20%;
  }
`;
const Right = styled.div`
  width: 86%;
  max-height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
