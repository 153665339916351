import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";
import {
  career_enquiries,
  careers_list,
  students,
  tabs,
} from "../../general/array";
import edit from "../../../assets/images/sidebar/Asset 30.svg";
import AddProgram from "../../modal/AddProgram";
import { Context } from "../../context/Store";
import api from "../../general/api";
import Nodata from "../../includes/noData/Nodata";
import ReactPaginate from "react-paginate";

function CareerEnquiries() {
  const [isModal, setModal] = useState(false);
  const [searchItems, setSearchItems] = useState("");
  const [careerEnquiries, setCareerEnquiries] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Career Enquiries",
      },
    });
  }, []);
  const access_token = user_data.access_token;
  useEffect(() => {
    api
      .get(
        `company_profile/get-career-enquiry/?q=${searchItems}`,

        {
          params: {
            page: currentPage,
          },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res) => {
        const data = res.app_data.data;
        const StatusCode = res.app_data.StatusCode;
        if (StatusCode == 6000) {
          setCareerEnquiries(data);
          setPagination(res.app_data.pagination_data);
        } else {
          setCareerEnquiries([]);
        }
      });
  }, [searchItems]);
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Enquiries</Title>
            <CoverTop>
              <SearchBar>
                <Input
                  type="search"
                  placeholder="Search..."
                  value={searchItems}
                  onChange={(e) => setSearchItems(e.target.value)}
                />
                <SearchIcon>
                  <i class="ri-search-line"></i>
                </SearchIcon>
              </SearchBar>
              {/* <Button onClick={() => setModal(true)}>Add Career</Button> */}
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">No</CardTit>
            <CardTit type="name">Name</CardTit>
            <CardTit type="name">Applied Date</CardTit>
            <CardTit type="name">Email</CardTit>
            <CardTit type="name">Job role</CardTit>
            <CardTit type="name">Phone</CardTit>
            <CardTit type="cv">CV</CardTit>
            {/* <CardTit type="em"></CardTit> */}
          </CardTitles>
          <Cards>
            {careerEnquiries?.length == 0 ? (
              <Nodata />
            ) : (
              careerEnquiries?.map((item, index) => (
                <Card key={index}>
                  <Name type="sl">{index + 1} </Name>
                  <Name type="name">{item.name} </Name>
                  <Name type="name">{item.date_added}</Name>
                  <Name type="name">{item.email} </Name>
                  <Name type="name">{item.job} </Name>
                  <Name type="name">{item.phone} </Name>
                  <Name type="cv">
                    <a href={item.cv} target="_blank" download>
                      Download
                      <Icon>
                        <i class="ri-download-2-line"></i>
                      </Icon>
                    </a>
                  </Name>
                  {/* <Name type="em">Delete</Name> */}
                </Card>
              ))
            )}
          </Cards>
          {pagination &&
            pagination.total_pages > 1 &&
            careerEnquiries.length > 0 && (
              <PaginationContainer>
                <PaginationText>
                  Showing enquiries : {pagination.first_item} -{" "}
                  {pagination.last_item} of {pagination.total_items} Enquiries
                </PaginationText>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pagination.total_pages}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />
              </PaginationContainer>
            )}
        </ProgramSection>
        <AddProgram isModal={isModal} setModal={setModal} />
      </Wrapper>
    </Container>
  );
}

export default CareerEnquiries;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Icon = styled.div`
  color: var(--textcolor);
  margin-left: 10px;
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "6%"
      : type == "name"
      ? "32%"
      : type == "user"
      ? "35%"
      : type == "phone"
      ? "25%"
      : type == "em"
      ? "8%"
      : type == "cv"
      ? "210px"
      : type == "ems"
      ? "8%"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ type }) =>
    type == "ems"
      ? "0"
      : type == "sl"
      ? "0 0 0 10px"
      : type === "cv"
      ? "0 15px 0 15px"
      : type === "em"
      ? "0 15px 0 15px"
      : "0 0 0 15px"};
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  box-sizing: border-box;
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "6%"
      : type == "name"
      ? "32%"
      : type == "user"
      ? "35%"
      : type == "phone"
      ? "25%"
      : type == "em"
      ? ""
      : type == "cv"
      ? "140px"
      : type == "ems"
      ? "8%"
      : ""};
  padding: ${({ type }) =>
    type == "ems"
      ? "0"
      : type == "sl"
      ? "0"
      : type === "cv"
      ? "0 15px 0 15px"
      : type === "em"
      ? "0 15px 0 15px"
      : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "flex-start"};
  cursor: ${({ type }) =>
    type == "ems"
      ? "pointer"
      : type == "em"
      ? "pointer"
      : type == "cv"
      ? "pointer"
      : ""};
  height: 50px;
  color: ${({ type }) =>
    type == "em" ? "#a35252" : type === "cv" ? "#6a6aff" : "#ffffff8e"};

  display: flex;
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  a {
    display: flex;
    text-decoration: none;
    color: #6a6aff;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
// const CardTitles = styled.div``;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
