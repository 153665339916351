import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import { achievements_list } from "../../general/array";
import CommonModal from "../../modal/CommonModal";
import { Context } from "../../context/Store";
import api from "../../general/api";
import Nodata from "../../includes/noData/Nodata";
import ConfirmationModal from "../../modal/ConfirmationModal";
import AddGalleryImages from "../../modal/AddGalleryImages";
import ReactPlayer from "react-player";

const Gallery = () => {
  const [getImages, setImages] = useState([]);
  const [isModal, setModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const access_token = user_data.access_token;

  //gallery items
  useEffect(() => {
    api
      .get(`company_profile/get-galleries-admin/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const StatusCode = res.app_data.StatusCode;
        const Data = res.app_data.data;
        if (StatusCode == 6000) {
          setImages(Data);
        } else {
          setImages([]);
        }
      });
  }, [isDelete, isModal]);

  const playerStyle = {
    width: "100%",
    height: "573px",
    maxWidth: "500px",
    margin: "0 auto",
    // inset: "217px",
  };
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Gallery",
      },
    });
  }, []);
  const deleteItems = () => {
    api
      .post(
        `company_profile/delete-gallery-image/${selectedId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setDelete(false);
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Gallery</Title>
            <CoverTop>
              <Button onClick={() => setModal(true)}>Add </Button>
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">Sl No</CardTit>
            <CardTit type="name">Video</CardTit>
            <CardTit type="names">Action</CardTit>
          </CardTitles>
          <Cards>
            {getImages?.length == 0 ? (
              <Nodata />
            ) : (
              getImages?.map((item, index) => (
                <Card key={index}>
                  <Name type="sl">{index + 1} </Name>
                  <Name type="name">
                    <ImageCover>
                      {item.type == "link" ? (
                        <ReactPlayer
                          url={item.file_link}
                          controls
                          style={playerStyle}
                          height="573px"
                          margin="150px 0 0 0"
                          width="100%"
                          className="videoplayer"
                        />
                      ) : (
                        <video
                          id="video"
                          width="200"
                          height="150"
                          loop
                          muted
                          autoPlay
                          playsInline
                        >
                          <source src={item.file} type="video/mp4" />
                          <source src={item.file} type="video/ogg" />
                        </video>
                      )}
                    </ImageCover>
                  </Name>
                  <Name
                    onClick={() => {
                      setDelete(true);
                      setSelectedId(item.id);
                    }}
                    type="names"
                    className="delete"
                  >
                    Delete
                  </Name>
                </Card>
              ))
            )}
          </Cards>
        </ProgramSection>
      </Wrapper>
      <AddGalleryImages isModal={isModal} setModal={setModal} />
      <ConfirmationModal
        isModal={isDelete}
        setModal={setDelete}
        submitFunction={deleteItems}
      />
    </Container>
  );
};

export default Gallery;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 180px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "7%"
      : type == "name"
      ? "30%"
      : type == "names"
      ? "7%"
      : type == "user"
      ? "30%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 20px;
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "7%"
      : type == "name"
      ? "30%"
      : type == "names"
      ? "7%"
      : type == "user"
      ? "30%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  height: ${({ type }) =>
    type == "user" ? "150px" : type == "name" ? "150px" : "50px"};
  padding: ${({ type }) =>
    type == "ems" ? "0" : type == "sl" ? "0" : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "center"};
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  display: flex;
  color: ${({ type }) => (type == "em" ? "#a35252" : "#ffffff8e")};
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &.text {
    font-size: 14px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.delete {
    color: #a35252;
    padding: 0px;
    cursor: pointer;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
const ImageCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50%;
    display: block;
  }
`;
