import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { api } from "../../axiosConfig";
import { Context } from "../context/Store";
import ButtonLoader from "../includes/loader/ButtonLoader";

function AddTopic({
  isModal,
  setModal,
  type,
  dayClick,
  modalType,
  topicId,
  content,
}) {
  const [file, setFile] = useState();
  const [audioFile, setAudioFile] = useState();
  const [imageFile, setImageFile] = useState();
  const [getText, setGetText] = useState();
  const [orderId, setOrderId] = useState();
  const [getData, setGetData] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [isLoading, setLoading] = useState(false);
  const handleClearFileInput = () => {
    setFileInputKey(Date.now());
  };
  const {
    state: { user_data },
  } = useContext(Context);

  const handleText = (value) => {
    setGetText(value);
  };
  //add daily topics
  const formData = new FormData();
  formData.append("day", dayClick);
  formData.append("topic_type", type);
  if (type == "image") {
    formData.append("file", imageFile);
  }
  if (type == "audio") {
    formData.append("file", audioFile);
  }
  if (type == "video") {
    formData.append("file", file);
  }
  formData.append("order_id", orderId);
  formData.append("text", getText);

  const access_token = user_data.access_token;
  console.log(audioFile, "audio");

  const clearFunction = () => {
    console.log("in fucntiohn");
    const fileInput = document.getElementById("fileInput");
    fileInput.value = "";
  };
  const addDailyTopicFunction = (e) => {
    e.preventDefault();
    if (type == "text") {
      if (!getText) {
        setErrorMsg("Text field is required");
      } else {
        setLoading(true);
        api
          .post(`courses/add-daily-topics/`, formData, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then((response) => {
            const Data = response.app_data.data;
            console.log(response);
            if (response.app_data.StatusCode === 6000) {
              setLoading(false);
              setModal(false);
              setOrderId("");
              clearFunction();
              handleClearFileInput();
              setAudioFile(null);
              setImageFile(null);
              setFile(null);
            } else {
              setModal(false);
              setLoading(false);
            }
          })
          .catch((error) => {});
      }
    } else {
      setLoading(true);
      api
        .post(`courses/add-daily-topics/`, formData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const Data = response.app_data.data;
          console.log(Data);
          if (response.app_data.StatusCode === 6000) {
            setLoading(false);
            setModal(false);
            setOrderId("");
            setErrorMsg("");
            handleClearFileInput();
            setAudioFile(null);
            setImageFile(null);
            setFile(null);
          } else {
            setErrorMsg("");
            setLoading(false);
          }
        })
        .catch((error) => {});
    }
  };
  //edit-------------------
  const editedFormData = new FormData();
  if (type == "image") {
    if (getData?.daily_image !== content) {
      editedFormData.append("file", imageFile);
    }
  }
  if (type == "audio") {
    if (getData?.audio !== content) {
      editedFormData.append("file", audioFile);
    }
  }
  if (type == "video") {
    if (getData?.video !== content) {
      editedFormData.append("file", file);
    }
  }
  if (type == "text") {
    if (getData?.daily_text !== content) {
      editedFormData.append("text", getText);
    }
  }
  if (getData?.order_id !== orderId) {
    editedFormData.append("order_id", orderId);
  }
  const editHandler = (e) => {
    e.preventDefault();

    setLoading(true);
    api
      .post(`courses/edit-daily-topics/${topicId}/`, editedFormData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setLoading(false);
          setModal(false);
          setOrderId("");
          setFile(null);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  //get single topic
  useEffect(() => {
    modalType == "edit" &&
      topicId.length > 0 &&
      api
        .get(`courses/daily-topic/${topicId}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const StatusCode = response.app_data.StatusCode;
          const Data = response.app_data.data;
          if (StatusCode === 6000) {
            setOrderId(Data.order_id);
            setGetText(Data.daily_text);
            if (type == "audio") {
              setFile(Data.audio);
            } else if (type == "video") {
              setFile(Data.video);
            } else if (type == "image") {
              setFile(Data.daily_image);
            }
            setGetData(Data);
          } else {
          }
        })
        .catch((error) => {});
  }, [topicId]);
  return (
    <>
      <MainContainer>
        {isModal ? (
          <Overlay
            onClick={() => {
              setModal(false);
              setErrorMsg("");
            }}
          ></Overlay>
        ) : (
          ""
        )}
        <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
          <Modal>
            <Container
              onSubmit={(e) =>
                modalType == "add"
                  ? addDailyTopicFunction(e)
                  : modalType == "edit"
                  ? editHandler(e)
                  : ""
              }
              type={type}
            >
              <Close
                onClick={() => {
                  setModal(false);
                  setErrorMsg("");
                }}
              >
                <i class="ri-close-circle-fill"></i>
              </Close>
              <Cover>
                <Label>Add {type}</Label>
                {type === "text" ? (
                  <TextEditor>
                    <ReactQuill
                      className="rich-text-area"
                      onChange={handleText}
                      required
                    />
                  </TextEditor>
                ) : (
                  <>
                    <Upload>
                      <UploadCover>
                        <span>
                          {file || imageFile || audioFile
                            ? "Uploaded"
                            : "Upload"}
                        </span>
                      </UploadCover>
                      <InputFile
                        type="file"
                        id="fileInput"
                        key={fileInputKey}
                        onChange={(e) => {
                          type == "image"
                            ? setImageFile(e.target.files[0])
                            : type == "audio"
                            ? setAudioFile(e.target.files[0])
                            : type == "video"
                            ? setFile(e.target.files[0])
                            : setFile(null);
                        }}
                        required
                      />
                    </Upload>
                    {file && (
                      <span className="tick">
                        <i class="ri-check-double-line"></i>
                      </span>
                    )}
                  </>
                )}
              </Cover>
              <Cover>
                <Label>Order ID</Label>
                <Input
                  type="number"
                  placeholder="0"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  required
                />
              </Cover>
              {isLoading ? (
                <Submit>
                  <ButtonLoader />
                </Submit>
              ) : (
                <Submits type="submit" value="Submit" />
              )}
              <Error>{errorMsg}</Error>
            </Container>
          </Modal>
        </BackContainer>
      </MainContainer>
    </>
  );
}
export default AddTopic;

const Error = styled.div`
  text-align: right;
  color: #fb5b5b;
`;
const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 113vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  /* max-width: 600px; */
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const MainContainer = styled.div``;
const TextEditor = styled.div`
  width: 80%;
`;
const Container = styled.form`
  padding: 50px;
  width: ${({ type }) => (type === "text" ? "50%" : "30%")};
  margin: 0 auto;
  position: relative;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;
const Cover = styled.div`
  display: flex;
  align-items: baseline;
  /* justify-content: center; */
  margin-bottom: 50px;
  gap: 20px;
  span {
    color: #9d9d9d;
    font-size: 14px;
    width: 100px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }
  span {
    &.tick {
      color: #8ef58e;
      width: 30px;
      height: 30px;
      border: 1px solid #8ef58e;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin-left: 20px;
      align-items: center;
    }
  }
`;
const Close = styled.div`
  position: absolute;
  color: #9d9d9d;
  font-size: 24px;
  cursor: pointer;
  right: 20px;
  top: 20px;
`;
const Input = styled.input`
  background-color: #5b5b5b;
  color: #fff;
  border: none;
  border-radius: 8px;
  outline: none;
  width: 80px;
  font-size: 18px;
  height: 40px;
  font-family: "mont-medium";
  padding: 0 20px;
  box-sizing: border-box;
`;
const Submit = styled.div`
  background-color: var(--lightblue);
  height: 40px;
  color: #000;
  cursor: pointer;
  font-family: "mont-medium";
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 18px;
  border-radius: 8px;
  box-sizing: border-box;
`;
const Submits = styled.input`
  background-color: var(--lightblue);
  height: 40px;
  border: none;
  color: #000;
  cursor: pointer;
  font-family: "mont-medium";
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 18px;
  border-radius: 8px;
  box-sizing: border-box;
`;
const Label = styled.div`
  text-transform: capitalize;
  width: 20%;
`;
const Upload = styled.div`
  cursor: pointer;
`;
const UploadCover = styled.div`
  background-color: #5b5b5b;
  color: #c8c8c8;
  position: absolute;
  padding: 10px 16px;
  border-radius: 8px;
  width: fit-content;
  span {
    border: 1px dotted#c8c8c8;
    padding: 6px 10px;
    border-radius: 8px;
    font-size: 14px;
  }
`;
const InputFile = styled.input`
  opacity: 0;
  height: 50px;
  width: 106px;
`;
