import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import { ordered_content, preview_content, tabs } from "../../general/array";
import AddDaysModal from "../../modal/AddDaysModal";
import HeadTopBar from "../../includes/HeadTopBar";
import text from "../../../assets/images/topic/Text.svg";
import edit from "../../../assets/images/sidebar/Asset 30.svg";
import AddTopic from "../../modal/AddTopic";
import ReactQuill from "react-quill";
import { Context } from "../../context/Store";
import api from "../../general/api";
import ConfirmationModal from "../../modal/ConfirmationModal";
import Nodata from "../../includes/noData/Nodata";

function Topics() {
  const [isClicked, setClicked] = useState("");
  const [addContent, setAddContent] = useState("");
  const [dayClick, setDayClick] = useState();
  const [content, setContent] = useState("");
  const [addDayCount, setAddCount] = useState(60);
  const [isTopicModal, setTopicModal] = useState(false);
  const [fetchContent, setFetchContent] = useState("");
  const [getProgrammes, setProgrammes] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [daysList, setDaysList] = useState();
  const [getTopics, setGetTopics] = useState([]);
  const [topicId, setTopicId] = useState("");
  const [isDelete, setDelete] = useState(false);
  const [modalType, setModalType] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Topic",
      },
    });
  }, []);

  //programm listing
  useEffect(() => {
    api
      .get("courses/programmes/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.app_data;
        const program_data = response.app_data.data;
        if (StatusCode === 6000) {
          setProgrammes(response.app_data.data);
          setSelectedId(program_data[0].id);
          setClicked(program_data[0].name);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
        // setError(true)
      });
  }, []);

  //program days listing
  useEffect(() => {
    api
      .get(`courses/days-list/?programme_id=${selectedId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const StatusCode = response.app_data.StatusCode;
        const Data = response.app_data.data;
        if (StatusCode === 6000) {
          setDaysList(Data);
          setDayClick(Data[0].id);
        } else {
          setDaysList([]);
        }
      })
      .catch((error) => {});
  }, [isClicked, selectedId]);

  //daily topic
  useEffect(() => {
    dayClick &&
      api
        .get(`courses/get-admin-daily-topics/${dayClick}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const StatusCode = response.app_data.StatusCode;
          const Data = response.app_data.data;
          if (StatusCode === 6000) {
            setGetTopics(Data);
            setContent(response.app_data.number_of_contents_in_a_day);
          } else {
            setGetTopics([]);
          }
        })
        .catch((error) => {});
  }, [dayClick, isTopicModal, isDelete]);

  const deleteDailyTopic = () => {
    topicId &&
      api
        .post(
          `courses/delete-daily-topics/${topicId}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((response) => {
          if (response.app_data.StatusCode === 6000) {
            setDelete(false);
          } else {
          }
        })
        .catch((error) => {});
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <DaysSection>
          <TitleDay>Topic</TitleDay>
          <DaysCollection>
            <Tabs>
              {getProgrammes.map((item, index) => (
                <Tab
                  className={selectedId == item.id && "active"}
                  onClick={() => setSelectedId(item.id)}
                  key={index}
                >
                  {item.name}
                </Tab>
              ))}
            </Tabs>
            <DaysColoumn>
              <DaysBar>
                <LabelDay>Days</LabelDay>
                <CoverDay>
                  {daysList?.map((item, index) => (
                    <DayCount
                      className={dayClick == item.id && "active"}
                      key={index}
                      onClick={() => {
                        setDayClick(item.id);
                      }}
                    >
                      Day {item.day_number}
                    </DayCount>
                  ))}
                </CoverDay>
              </DaysBar>
              <EditSection>
                <AddSec onClick={() => setModalType("add")}>
                  <Option
                    className={addContent == "text" && "active"}
                    onClick={() => {
                      setAddContent("text");
                      daysList.length > 0 && setTopicModal(true);
                    }}
                  >
                    Add Text
                  </Option>
                  <Option
                    className={addContent == "audio" && "active"}
                    onClick={() => {
                      setAddContent("audio");
                      daysList.length > 0 && setTopicModal(true);
                    }}
                  >
                    Add Audio
                  </Option>
                  <Option
                    className={addContent == "image" && "active"}
                    onClick={() => {
                      setAddContent("image");
                      daysList.length > 0 && setTopicModal(true);
                    }}
                  >
                    Add Image
                  </Option>
                  <Option
                    className={addContent == "video" && "active"}
                    onClick={() => {
                      setAddContent("video");
                      daysList.length > 0 && setTopicModal(true);
                    }}
                  >
                    Add Video
                  </Option>
                </AddSec>
                <ContentNumberDiv>
                  <ContentNumber>
                    Number of contents : {daysList?.length > 0 ? content : "0"}
                  </ContentNumber>
                </ContentNumberDiv>
                <Preview>
                  <LabelContent>
                    <LContent type="id">Sl No</LContent>
                    <LContent type="con">Content</LContent>
                    <LContent type="order">
                      Order <br /> ID
                    </LContent>
                  </LabelContent>
                  <PreviewContent>
                    {getTopics.length == 0 || daysList.length == 0 ? (
                      <Nodata />
                    ) : (
                      getTopics?.map((item, index) => (
                        <CoverPreviewContent key={item.id}>
                          <PContent type="id">{index + 1} </PContent>
                          {item.type == "text" ? (
                            <PContent type="con">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.daily_text,
                                }}
                              />
                            </PContent>
                          ) : item.type == "image" ? (
                            <PContent type="con">
                              <ComprssdImg>
                                <img src={item.daily_image} alt="" />
                              </ComprssdImg>
                            </PContent>
                          ) : item.type == "video" ? (
                            <PContent type="con">
                              <VideoCover>
                                <video
                                  id="video"
                                  width="200"
                                  height="150"
                                  loop
                                  muted
                                  autoPlay
                                  playsInline
                                >
                                  <source src={item.video} type="video/mp4" />
                                  <source src={item.video} type="video/ogg" />
                                </video>
                              </VideoCover>
                            </PContent>
                          ) : item.type == "audio" ? (
                            <PContent type="con">
                              <AudioCover>
                                <audio controls>
                                  <source src={item.audio} type="audio/ogg" />
                                  <source src={item.audio} type="audio/mpeg" />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </AudioCover>
                            </PContent>
                          ) : (
                            ""
                          )}
                          <PContent type="order">
                            <Order>{item.order_id} </Order>
                            <Order
                              onClick={() => {
                                setAddContent(item.type);
                                setTopicId(item.id);
                                setModalType("edit");
                                if (item.type == "audio") {
                                  setFetchContent(item.audio);
                                } else if (item.type == "image") {
                                  setFetchContent(item.daily_image);
                                } else if (item.type == "video") {
                                  setFetchContent(item.video);
                                } else if (item.type == "image") {
                                  setFetchContent(item.daily_image);
                                }
                                setTopicModal(true);
                              }}
                            >
                              <img src={edit} alt="icon" />
                            </Order>
                            <Dele
                              onClick={() => {
                                setTopicId(item.id);
                                setDelete(true);
                              }}
                            >
                              Delete
                            </Dele>
                          </PContent>
                        </CoverPreviewContent>
                      ))
                    )}
                  </PreviewContent>
                </Preview>
              </EditSection>
            </DaysColoumn>
          </DaysCollection>
        </DaysSection>

        <AddTopic
          setModal={setTopicModal}
          isModal={isTopicModal}
          type={addContent}
          modalType={modalType}
          topicId={topicId}
          dayClick={dayClick}
          content={fetchContent}
        />
        <ConfirmationModal
          isModal={isDelete}
          setModal={setDelete}
          submitFunction={deleteDailyTopic}
        />
      </Wrapper>
    </Container>
  );
}

export default Topics;

const Container = styled.div`
  padding: 80px 0;
  overflow-y: hidden;
`;

const Wrapper = styled.div``;
const AudioCover = styled.div`
  /* margin-left: 20px; */
`;
const VideoCover = styled.div`
  /* margin-left: 20px; */
`;
const ComprssdImg = styled.div`
  width: 100px;
  /* margin-left: 20px; */
`;

const DaysSection = styled.div`
  margin-top: 40px;
`;
const TitleDay = styled.h3`
  margin-bottom: 25px;
`;
const DaysCollection = styled.div``;
const Tabs = styled.div`
  display: flex;
`;
const Tab = styled.div`
  background-color: #0c0d0f;
  font-size: 14px;
  padding: 16px 30px;
  width: 100px; /* Set the width of the container */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--bordercolor);
  color: #ffffff8e;
  cursor: pointer;
  margin-right: 3px;
  transition: 0.4s ease;
  &.active {
    transition: 0.4s ease;
    border-radius: 0px 20px 0 0;
    background-color: var(--darkgrey);
    border-left: 1px solid var(--bordercolor);
    border-right: 1px solid var(--bordercolor);
    border-top: 1px solid var(--bordercolor);
    border-bottom: 1px solid transparent;
  }
`;
const DaysColoumn = styled.div`
  background-color: var(--darkgrey);
  padding: 30px 60px;
  border-left: 1px solid var(--bordercolor);
  border-right: 1px solid var(--bordercolor);
  border-bottom: 1px solid var(--bordercolor);
  display: flex;
  justify-content: space-between;
`;
const DaysBar = styled.div`
  width: 15%;
`;
const DayCount = styled.div`
  background-color: var(--lightgrey);
  border: 1px solid var(--bordercolor);
  width: 85px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  height: 60px;
  color: #ffffff8e;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  &.active {
    color: var(--lightblue);
    border-color: 1px solid var(--lightblue);
  }
`;
const EditSection = styled.div`
  width: 90%;
`;
const LabelDay = styled.div`
  margin-bottom: 10px;
  font-family: "mont-light";
  color: #ffffff8e;
`;
const AddSec = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #6262628e;
`;
const CoverDay = styled.div`
  max-height: 100%;
  overflow: scroll;
  max-height: calc(100vh - 224px);
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Option = styled.div`
  background-color: var(--lightgrey);
  border: 1px solid #585757;
  font-size: 14px;
  width: 24%;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  color: #ffffff8e;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  &.active {
    color: var(--lightblue);
    border-color: 1px solid var(--lightblue);
  }
`;

const Preview = styled.div`
  margin-top: 30px;
`;
const LabelContent = styled.div`
  display: flex;
`;
const LContent = styled.div`
  font-size: 14px;
  width: ${({ type }) =>
    type === "id"
      ? "8%"
      : type === "con"
      ? "70%"
      : type === "order"
      ? "22%"
      : ""};
  color: #ffffff8e;
`;
const PreviewContent = styled.div`
  color: #ffffff8e;
`;
const CoverPreviewContent = styled.div`
  display: flex;
  align-items: baseline;
  align-items: flex-start;
`;
const PContent = styled.div`
  width: ${({ type }) =>
    type === "id"
      ? "8%"
      : type === "con"
      ? "70%"
      : type === "order"
      ? "22%"
      : ""};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 20px;
  border: ${({ type }) =>
    type === "id"
      ? "1px solid var(--bordercolor)"
      : type === "con"
      ? "1px solid var(--bordercolor)"
      : type === "order"
      ? "none"
      : ""};
  padding: ${({ type }) => (type == "con" ? "20px 40px" : "10px")};
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 8px;
  margin-bottom: 20px;
`;
const Order = styled.div`
  width: 15px;
  padding: 10px;
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;
const Dele = styled.div`
  cursor: pointer;
  border: 1px solid var(--bordercolor);
  padding: 10px;
  color: #a35252;
  border-radius: 10px;
`;
const ContentNumberDiv = styled.div`
  width: 100%;
  margin-top: 10px;
`;
const ContentNumber = styled.span`
  text-align: center;
  display: block;
  font-family: "mont-bold";
  color: var(--lightblue);
  font-size: 14px;
`;
