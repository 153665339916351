import React from "react";
import styled from "styled-components";
import mob from "../../../assets/images/not-mob.jpg";

function NotinMobile() {
  return (
    <Container>
      <Content>
        <ImageContainer>
          <img src={mob} alt="image" />
        </ImageContainer>
        <Text>Not Compatible In Tab And Mobile devices</Text>
      </Content>
    </Container>
  );
}

export default NotinMobile;

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 65%;
  }
`;
const Content = styled.div``;
const Text = styled.div`
  text-align: center;
`;
