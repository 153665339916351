import React, { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import noData from "../../../assets/images/no-data/no-data.svg";
import CommonModal from "../../modal/CommonModal";
import { Context } from "../../context/Store";
import ConfirmationModal from "../../modal/ConfirmationModal";
import api from "../../general/api";
import Nodata from "../../includes/noData/Nodata";
import ReactPaginate from "react-paginate";

const Testimonials = () => {
  const [testimonialModal, setTestimonialModal] = useState(false);
  const [searchedItems, setSearchedItems] = useState("");
  const [isDelete, setDelete] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [getTestimonials, setTestimonials] = useState([]);
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Testimonials",
      },
    });
  }, []);
  // ---------------get testimonials-----------------------
  useEffect(() => {
    api
      .get(`company_profile/testimonials/?q=${searchedItems}`, {
        params: {
          page: currentPage,
        },
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const StatusCode = res.app_data.StatusCode;
        const Data = res.app_data.data;
        if (StatusCode == 6000) {
          setTestimonials(Data);
          setPagination(res.app_data.pagination_data);
        } else {
          setTestimonials([]);
        }
      });
  }, [testimonialModal, isDelete, searchedItems, currentPage]);

  //  ---------------------Delete testimonial---------------------
  const handleTeamDelete = () => {
    api
      .post(
        `company_profile/delete-testimonial/${selectedId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setDelete(false);
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Testimonials</Title>
            <CoverTop>
              <SearchBar>
                <Input
                  type="search"
                  placeholder="Search..."
                  value={searchedItems}
                  onChange={(e) => setSearchedItems(e.target.value)}
                />
                <SearchIcon>
                  <i class="ri-search-line"></i>
                </SearchIcon>
              </SearchBar>
              <Button onClick={() => setTestimonialModal(true)}>
                Add Testimonial
              </Button>
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">Sl No</CardTit>
            <CardTit type="name">name</CardTit>
            <CardTit type="count">Rating count</CardTit>
            <CardTit type="user">Description</CardTit>
            <CardTit type="im">Image</CardTit>
            <CardTit type="im">Video</CardTit>
            <CardTit type="delete"></CardTit>
          </CardTitles>
          <Cards>
            {getTestimonials?.length == 0 ? (
              <Nodata />
            ) : (
              getTestimonials?.map((item, index) => (
                <Card key={index}>
                  <Name type="sl">{index + 1}</Name>
                  <Name type="name">{item.name} </Name>
                  <Name type="count">{item.rating_count}</Name>
                  <Name type="user" className="text">
                    {item.quote}
                  </Name>
                  <Name type="im">
                    {item.image == null ? (
                      <ImageCover>
                        <img src={noData} alt="User-image" />
                      </ImageCover>
                    ) : (
                      <ImageCover>
                        <img src={item.image} alt="User-image" />
                      </ImageCover>
                    )}
                  </Name>
                  <Name type="im">
                    {item.video == null ? (
                      <ImageCover>
                        <img src={noData} alt="User-image" />
                      </ImageCover>
                    ) : (
                      <VideoCover>
                        <video
                          id="video"
                          width="200"
                          height="150"
                          loop
                          muted
                          autoPlay
                          playsInline
                        >
                          <source src={item.video} type="video/mp4" />
                          <source src={item.video} type="video/ogg" />
                        </video>
                      </VideoCover>
                    )}
                  </Name>
                  <Name
                    onClick={() => {
                      setDelete(true);
                      setSelectedId(item.id);
                    }}
                    type="delete"
                    className="delete"
                  >
                    <i class="ri-delete-bin-6-line"></i>
                  </Name>
                </Card>
              ))
            )}
          </Cards>
          {pagination &&
            pagination.total_pages > 1 &&
            getTestimonials.length > 0 && (
              <PaginationContainer>
                <PaginationText>
                  Showing testimonials : {pagination.first_item} -{" "}
                  {pagination.last_item} of {pagination.total_items}{" "}
                  Testimonials
                </PaginationText>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pagination.total_pages}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />
              </PaginationContainer>
            )}
        </ProgramSection>
        <ConfirmationModal
          isModal={isDelete}
          setModal={setDelete}
          submitFunction={handleTeamDelete}
        />
        <CommonModal
          isModal={testimonialModal}
          setModal={setTestimonialModal}
          type="add_testimonial"
        />
      </Wrapper>
    </Container>
  );
};

export default Testimonials;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "delete"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "delete"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "250px"
      : type == "phone"
      ? "180px"
      : type == "count"
      ? "120px"
      : type == "em"
      ? "85px"
      : type == "im"
      ? "180px"
      : type == "ems"
      ? "45px"
      : type == "delete"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 10px;
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "250px"
      : type == "phone"
      ? "180px"
      : type == "count"
      ? "120px"
      : type == "em"
      ? "85px"
      : type == "im"
      ? "180px"
      : type == "ems"
      ? "45px"
      : type == "delete"
      ? "45px"
      : ""};
  height: ${({ type }) =>
    type == "user" ? "150px" : type == "im" ? "150px" : "50px"};
  padding: ${({ type }) =>
    type == "ems"
      ? "0"
      : type == "sl"
      ? "0"
      : type == "count"
      ? "0"
      : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems"
      ? "center"
      : type == "sl"
      ? "center"
      : type == "name"
      ? "flex-start"
      : "center"};
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  display: flex;
  color: ${({ type }) => (type == "em" ? "#a35252" : "#ffffff8e")};
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &.text {
    font-size: 12px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.delete {
    color: #a35252;
    padding: 0px;
    cursor: pointer;
    font-size: 18px;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
const ImageCover = styled.div`
  width: 60px;
  img {
    width: 100%;
    display: block;
  }
`;

const VideoCover = styled.div`
  /* margin-left: 20px; */
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  @media all and (max-width: 1380px) {
    padding: 15px 0px;
    margin-right: 40px;
  }
  @media all and (max-width: 1280px) {
    margin-right: 20px;
    padding: 20px 0px;
  }
  @media all and (max-width: 980px) {
    margin-right: 10px;
  }
  @media all and (max-width: 890px) {
    flex-direction: column;
  }
`;
const PaginationText = styled.p`
  color: #898989;
  font-size: 14px;
  @media all and (max-width: 890px) {
    margin-bottom: 10px;
  }
`;
