import React, { useContext, useState } from "react";

//images
import logo from "../../../assets/images/login/logo.png";
import { styled } from "styled-components";
import bg from "../../../assets/images/login/loginBanner.png";
import api from "../../general/api";
import { Context } from "../../context/Store";
import ButtonLoader from "../../includes/loader/ButtonLoader";
import Shake from "react-reveal/Shake";

function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isVisible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post("accounts/chief-login/", { username: username, password: password })
      .then((response) => {
        const { StatusCode, data } = response.app_data;
        if (StatusCode === 6000) {
          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              isLogin: true,
              access_token: data.access.access,
              refresh_token: data.access.refresh,
            },
          });
          setLoading(false);
        } else {
          setErrorMsg(data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // setError(true)
      });
  };

  return (
    <Container>
      <Wrapper>
        <Content>
          <Left>
            <Logo>
              <img src={logo} alt="logo" />
            </Logo>
          </Left>
          <Right>
            <Form onSubmit={(e) => submitHandler(e)}>
              <Title>LOGIN</Title>
              <Subtitle>Please enter your user name and password</Subtitle>
              <Input
                type="text"
                placeholder="User name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <Cover>
                <Input
                  type={isVisible ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Eye onClick={() => setVisible(!isVisible)}>
                  {!isVisible ? (
                    <i class="ri-eye-line"></i>
                  ) : (
                    <i class="ri-eye-off-line"></i>
                  )}
                </Eye>
              </Cover>
              {isLoading ? (
                <Buttons>
                  <ButtonLoader />
                </Buttons>
              ) : (
                <Button type="submit" value="Login" />
              )}
              {errorMsg && (
                <Shake>
                  <Error>{errorMsg} </Error>
                </Shake>
              )}
            </Form>
          </Right>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Login;

const Container = styled.div`
  height: 100vh;
`;
const Wrapper = styled.div``;
const Error = styled.div`
  text-align: center;
  color: #f94444;
  margin-top: 10px;
  font-family: "mont-medium";
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
`;
const Left = styled.div`
  background: url(${bg});
  width: 50%;
  background-size: cover;
`;
const Logo = styled.div`
  width: 20%;
  margin: 100px 0 0 150px;
`;
const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form`
  width: 40%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 50%;
  }
`;
const Title = styled.h2`
  color: var(--blue);
  font-family: "mont-medium";
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
`;
const Subtitle = styled.p`
  color: var(--blue);
  font-size: 14px;
  font-family: "mont-regular";
  text-align: center;
  margin-bottom: 40px;
`;
const Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 30px;
  margin-bottom: 20px;
`;
const Cover = styled.div`
  position: relative;
`;
const Buttons = styled.div`
  background-color: var(--blue);
  color: #fff;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`;
const Button = styled.input`
  background-color: var(--blue);
  color: #fff;
  border: none;
  height: 50px;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
`;
const Eye = styled.div`
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 15px;
`;
