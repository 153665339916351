import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../context/Store";
import ButtonLoader from "../includes/loader/ButtonLoader";
import api from "../general/api";

const EditPhone = ({
  isModal,
  setModal,
  itemData,
  setItemData,
  setPhone,
  phone,
  fetchWhatsAppApi,
}) => {
  const [isLoading, setLoading] = useState(false);

  const {
    state: { user_data },
  } = useContext(Context);

  const access_token = user_data.access_token;

  const handleModal = () => {
    setItemData("");
    setPhone("");
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if (itemData.phone !== phone) {
      formData.append("phone", phone);
    }

    api
      .post(`company_profile/edit-whatsapp-number/${itemData.id}/`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        if (res.data.app_data.StatusCode === 6000) {
          setPhone("");
          setLoading(false);
          setModal(false);
          fetchWhatsAppApi();
        } else {
          setLoading(true);
        }
      });
  };

  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => handleModal()}></Overlay>
      <Modal>
        <Containers onSubmit={(e) => handleSubmit(e)}>
          <Cover>
            <Label>Whatsapp Number</Label>

            <Input
              value={phone}
              type="text"
              placeholder="Enter Whatsapp Number"
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Cover>
          <Covers>
            {isLoading ? (
              <Submit>
                <ButtonLoader />
              </Submit>
            ) : (
              <Submits type="submit" Value="Submit" />
            )}
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
};

export default EditPhone;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: ${({ type }) =>
    type == "head" ? "flex-end" : " flex-start"};
  margin-bottom: 20px;
  align-items: center;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  &.check {
    height: unset;
    width: 20px;
  }
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.div`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Submits = styled.input`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  border: none;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;
