import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";
import { Context } from "../../context/Store";
import api from "../../general/api";
import AddStudent from "../../modal/AddStudent";
import Nodata from "../../includes/noData/Nodata";
import ConfirmationModal from "../../modal/ConfirmationModal";
import AddCount from "../../modal/AddCount";

function GrowthOverview() {
  const [isModal, setModal] = useState(false);
  const [getCounts, setCounts] = useState([]);
  const [modalType, setModalType] = useState();
  const [studentId, setStudentId] = useState();
  const [searchItems, setSearchItems] = useState("");
  const [isDelete, setDelete] = useState(false);

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Student",
      },
    });
  }, []);

  useEffect(() => {
    api
      .get(`company_profile/get-company-profile-count/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const Data = res.app_data.data;
        if (res.app_data.StatusCode == 6000) {
          setCounts(Data);
        }
      });
  }, []);
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Overview Count</Title>
            <CoverTop>
              <SearchBar>
                <Input
                  type="search"
                  placeholder="Search..."
                  value={searchItems}
                  onChange={(e) => setSearchItems(e.target.value)}
                />
                <SearchIcon>
                  <i class="ri-search-line"></i>
                </SearchIcon>
              </SearchBar>
              <Button
                onClick={() => {
                  setModal(true);
                  setModalType("add");
                }}
              >
                Add
              </Button>
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">Sl No</CardTit>
            <CardTit type="name">Title</CardTit>
            <CardTit type="ems"></CardTit>
            <CardTit type="ems"></CardTit>
          </CardTitles>
          <Cards>
            {getCounts?.length == 0 ? (
              <Nodata />
            ) : (
              getCounts?.map((item, index) => (
                <Card key={index}>
                  <Name type="sl">{index + 1} </Name>
                  <Name type="name">{item.name} </Name>
                  <Name
                    type="ems"
                    onClick={() => {
                      setModalType("edit");
                      setModal(true);
                      setStudentId(item.id);
                    }}
                  >
                    <i class="ri-edit-2-line"></i>
                  </Name>
                  <Name
                    type="ems"
                    onClick={() => {
                      setDelete(true);
                      setStudentId(item.id);
                    }}
                  >
                    <i class="ri-delete-bin-6-line"></i>
                  </Name>
                </Card>
              ))
            )}
          </Cards>
        </ProgramSection>
        <AddCount setModal={setModal} isModal={isModal} />
      </Wrapper>
    </Container>
  );
}

export default GrowthOverview;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 10px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "200px"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 20px;
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "200px"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  padding: ${({ type }) =>
    type == "ems" ? "0" : type == "sl" ? "0" : "0 0 0 10px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "flex-start"};
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  height: 50px;
  display: flex;
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
`;
// const CardTitles = styled.div``;
