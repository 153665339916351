import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";
import { tabs } from "../../general/array";
import edit from "../../../assets/images/sidebar/Asset 30.svg";
import AddProgram from "../../modal/AddProgram";
import api from "../../general/api";
import { Context } from "../../context/Store";
import ConfirmationModal from "../../modal/ConfirmationModal";

function Programs() {
  const [isModal, setModal] = useState(false);
  const [programmes, setProgrammes] = useState();
  const [programId, setProgramId] = useState();
  const [modalType, setModalType] = useState();
  const [isDelete, setDelete] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Programs",
      },
    });
  }, []);

  useEffect(() => {
    api
      .get("courses/programmes/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode } = response.app_data;
        if (StatusCode === 6000) {
          setProgrammes(response.app_data.data);
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
        // setError(true)
      });
  }, [isModal, isDelete]);
  // -------------------delete-----------------
  const handleDelete = () => {
    api
      .post(
        `courses/delete-programme/${selectedId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setDelete(false);
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Programs</Title>
            <Button
              onClick={() => {
                setModal(true);
                setModalType("add");
              }}
            >
              Add Program
            </Button>
          </TopSection>
          <Cards>
            {programmes?.map((item, index) => (
              <Card key={index}>
                <Number>{index + 1} </Number>
                <TextBar>
                  <span> {item.name} </span>
                  <Icon
                    onClick={() => {
                      setModal(true);
                      setProgramId(item.id);
                      setModalType("edit");
                    }}
                  >
                    <img src={edit} alt={item.title} />
                  </Icon>
                </TextBar>
                <Delete
                  onClick={() => {
                    setDelete(true);
                    setSelectedId(item.id);
                  }}
                >
                  Delete
                </Delete>
              </Card>
            ))}
          </Cards>
        </ProgramSection>
        <ConfirmationModal
          isModal={isDelete}
          setModal={setDelete}
          submitFunction={handleDelete}
        />
        <AddProgram
          isModal={isModal}
          setModal={setModal}
          programId={programId}
          modalType={modalType}
        />
      </Wrapper>
    </Container>
  );
}

export default Programs;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 100%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;
