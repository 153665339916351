import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Context } from "../context/Store";
import { api } from "../../axiosConfig";
import ButtonLoader from "../includes/loader/ButtonLoader";

function AddTeam({ isModal, setModal, type, studentId }) {
  const [designation, setDesignation] = useState();
  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [getTeam, setGetTeam] = useState("");
  const [departpment, setDepartment] = useState("");
  const [departpmentId, setDepartmentId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const {
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;

  // -----------------Add Team------------------------
  const formData = new FormData();
  if (getTeam.name !== name) {
    formData.append("name", name);
  }
  if (getTeam.photo !== file) {
    formData.append("photo", file);
  }
  if (getTeam.designation !== designation) {
    formData.append("designation", designation);
  }
  if (getTeam.department !== departpment) {
    formData.append("department", departpmentId);
  }
  formData.append("head", isChecked ? "True" : "False");

  const assignHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        "company_profile/add-our-team/",
        formData,

        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setName("");
          setDesignation("");
          setFile("");
          setModal(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  //   --------------edit student profile-----------------------
  const assignEditStudent = (e) => {
    e.preventDefault();
    setLoading(true);

    api
      .post(
        `company_profile/edit-our-team/${studentId}/`,
        formData,

        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        if (response.app_data.StatusCode === 6000) {
          setName("");
          setDesignation("");
          setFile("");
          setLoading(false);
          setModal(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {});
  };
  // --------------get single student---------------------
  useEffect(() => {
    studentId &&
      type == "edit" &&
      api
        .get(`company_profile/get-our-team-admin/${studentId}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          const StatusCode = res.app_data.StatusCode;
          const Data = res.app_data.data;
          if (StatusCode == 6000) {
            setGetTeam(Data);
            setName(Data.name);
            setDesignation(Data.designation);
            setFile(Data.photo);
            setDepartment(Data.department);
            setIsChecked(Data.head);
          } else {
            //   setTeamList([]);
          }
        });
  }, [isModal]);
  useEffect(() => {
    api
      .get(`company_profile/get-department/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;
        if (StatusCode == 6000) {
          setDepartment(Data);
          console.log(Data, "sepete");
        } else {
          //   setTeamList([]);
        }
      });
  }, []);
  const handleCheckboxChange = () => {
    // Toggle the state when the checkbox is clicked
    setIsChecked(!isChecked);
  };
  const handleChange = (e) => {
    setDepartmentId(e.target.value);
  };
  return (
    <Container className={isModal && "active"}>
      <Overlay onClick={() => setModal(false)}></Overlay>
      <Modal>
        <Containers
          onSubmit={(e) =>
            type == "add" ? assignHandler(e) : assignEditStudent(e)
          }
        >
          <Cover>
            <Label>Department</Label>
            <Select value={departpmentId} onChange={handleChange}>
              <option>Select Department</option>
              {departpment &&
                departpment?.map((t) => <option value={t.id}>{t.name}</option>)}
            </Select>
          </Cover>
          <Cover>
            <Label>Name</Label>
            {type == "add" ? (
              <Input
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            ) : (
              <Input
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            )}
          </Cover>
          <Cover>
            <Label>Designation</Label>
            <CoverInput>
              {type == "add" ? (
                <Input
                  // className="num"
                  required
                  type="text"
                  placeholder=""
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              ) : (
                <Input
                  // className="num"
                  type="text"
                  placeholder=""
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              )}
            </CoverInput>
          </Cover>
          <Cover>
            <Label>Image</Label>
            {type == "add" ? (
              <Input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                required
              />
            ) : (
              <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
            )}
          </Cover>
          <Cover type="head">
            <Label>Head of the department</Label>
            <Input
              type="checkbox"
              className="check"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Cover>
          <Covers>
            {isLoading ? (
              <Submit>
                <ButtonLoader />
              </Submit>
            ) : (
              <Submits type="submit" Value="Submit" />
            )}
          </Covers>
        </Containers>
      </Modal>
    </Container>
  );
}
export default AddTeam;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    backdrop-filter: blur(4px);
  }
`;
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 1000;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 736px;
  max-height: 100vh;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
  z-index: 1000;
  background: #232327;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0px 3px 56px #000;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1380px) {
    left: 58%;
  }
  @media all and (max-width: 1280px) {
    width: 550px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 450px;
  }
  @media all and (max-width: 768px) {
    width: 400px;
  }
  @media all and (max-width: 640px) {
    width: 350px;
  }
  @media all and (max-width: 480px) {
    width: 330px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const Select = styled.select`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const CoverInput = styled.div`
  width: 100%;
`;
const Containers = styled.form`
  padding: 50px 80px;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  border-radius: 10px;
  @media all and (max-width: 640px) {
    padding: 20px;
  }
`;

const Covers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;
const Cover = styled.div`
  display: flex;
  justify-content: ${({ type }) =>
    type == "head" ? "flex-end" : " flex-start"};
  margin-bottom: 20px;
`;
const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
  &.check {
    height: unset;
    width: 20px;
  }
`;
const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;
const Submit = styled.div`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Submits = styled.input`
  background-color: var(--lightblue);
  color: #000;
  width: 150px;
  border: none;
  height: 40px;
  font-family: "mont-medium";
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Sub = styled.div`
  display: flex;
  width: 60%;
`;
