import React, { useContext, useEffect, useState } from "react";
import HeadTopBar from "../../includes/HeadTopBar";
import { styled } from "styled-components";
import { careers_list, students, tabs } from "../../general/array";
import edit from "../../../assets/images/sidebar/Asset 30.svg";
import AddProgram from "../../modal/AddProgram";
import { Context } from "../../context/Store";
import api from "../../general/api";
import Nodata from "../../includes/noData/Nodata";
import AddCareer from "../../modal/AddCareer";
import ConfirmationModal from "../../modal/ConfirmationModal";

function Career() {
  const [isModal, setModal] = useState(false);
  const [getCareers, setCareers] = useState([]);
  const [isDelete, setDelete] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [modalType, setModalType] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const access_token = user_data.access_token;
  useEffect(() => {
    dispatch({
      type: "UPDATE_USER_DATA",
      user_data: {
        active_menu: "Career",
      },
    });
  }, []);
  useEffect(() => {
    api
      .get("company_profile/get-careers/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const StatusCode = res.app_data.StatusCode;
        const Data = res.app_data.data;
        if (StatusCode == 6000) {
          setCareers(Data);
        } else {
          setCareers([]);
        }
      });
  }, [isModal, isDelete]);

  // --------------delete--------------------
  const handleCareerDelete = () => {
    api
      .post(
        `company_profile/delete-careers/${selectedId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.app_data.StatusCode === 6000) {
          setDelete(false);
        } else {
        }
      })
      .catch((error) => {});
  };
  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>Careers</Title>
            <CoverTop>
              {/* <SearchBar>
                <Input type="search" placeholder="Search..." />
                <SearchIcon>
                  <i class="ri-search-line"></i>
                </SearchIcon>
              </SearchBar> */}
              <Button onClick={() => setModal(true)}>Add Career</Button>
            </CoverTop>
          </TopSection>
          <CardTitles>
            <CardTit type="sl">Sl No</CardTit>
            <CardTit type="name">Designation</CardTit>
            <CardTit type="name">Job Type</CardTit>
            <CardTit type="user">Description</CardTit>
            <CardTit type="em"></CardTit>
          </CardTitles>
          <Cards>
            {getCareers?.length == 0 ? (
              <Nodata />
            ) : (
              getCareers?.map((item, index) => (
                <Card key={index}>
                  <Name type="sl">{index + 1} </Name>
                  <Name type="name">{item.designation} </Name>
                  <Name type="name">{item.job_type} </Name>
                  <Name type="user" className="text">
                    {item.job_description}
                  </Name>
                  <Name
                    type="em"
                    onClick={() => {
                      setDelete(true);
                      setSelectedId(item.id);
                      setModalType("career");
                    }}
                  >
                    Delete
                  </Name>
                </Card>
              ))
            )}
          </Cards>
        </ProgramSection>
        <AddCareer isModal={isModal} setModal={setModal} />
        <ConfirmationModal
          isModal={isDelete}
          setModal={setDelete}
          submitFunction={handleCareerDelete}
        />
      </Wrapper>
    </Container>
  );
}

export default Career;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;
const CoverTop = styled.div`
  display: flex;
`;
const SearchBar = styled.div`
  border: 1px solid #5a5d61;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  margin-right: 20px;
`;
const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
  height: 40px;
  color: #fff;
  padding: 0 20px;
  border-radius: 8px;
`;
const SearchIcon = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: "|";
    color: #5a5d61;
    left: -10px;
  }
`;
const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 30px;
`;

const Delete = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

const CardTitles = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
`;
const CardTit = styled.div`
  background-color: ${({ type }) =>
    type == "em"
      ? "transparent"
      : type == "ems"
      ? "transparent"
      : "var(--darkgrey)"};
  border: ${({ type }) =>
    type == "em"
      ? "none"
      : type == "ems"
      ? "none"
      : "1px solid var(--bordercolor)"};

  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "40%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  display: flex;
  height: 40px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0px 0 20px;
  box-sizing: border-box;
`;
const Edit = styled.div``;
const Name = styled.div`
  border: ${({ type }) =>
    type == "em"
      ? "1px solid var(--bordercolor)"
      : type == "ems"
      ? "1px solid var(--bordercolor)"
      : "1px solid #646464"};
  background-color: ${({ type }) =>
    type == "em" ? "var(--darkgrey)" : type == "ems" ? "var(--darkgrey)" : ""};
  width: ${({ type }) =>
    type == "sl"
      ? "85px"
      : type == "name"
      ? "220px"
      : type == "user"
      ? "40%"
      : type == "phone"
      ? "180px"
      : type == "em"
      ? "85px"
      : type == "ems"
      ? "45px"
      : ""};
  height: ${({ type }) => (type == "user" ? "150px" : "50px")};
  padding: ${({ type }) =>
    type == "ems" ? "0" : type == "sl" ? "0" : "0 0 0 15px"};
  justify-content: ${({ type }) =>
    type == "ems" ? "center" : type == "sl" ? "center" : "flex-start"};
  cursor: ${({ type }) =>
    type == "ems" ? "pointer" : type == "em" ? "pointer" : ""};

  display: flex;
  color: ${({ type }) => (type == "em" ? "#a35252" : "#ffffff8e")};
  border-radius: 8px;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &.text {
    font-size: 14px;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media all and (max-width: 1440px) {
    font-size: 14px;
  }
`;
// const CardTitles = styled.div``;
